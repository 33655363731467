.BreadcrumbsMenu :global(.ant-menu-item-group-title) {
  padding: 12px 20px 0;
  @apply text-xs px-5;
}

.BreadcrumbsMenu :global(.ant-menu-item-group-list .ant-menu-item),
.BreadcrumbsMenu :global(.ant-menu-item-group-list .ant-menu-submenu-title) {
  padding: 0 20px;
  @apply px-5;
}

.BreadcrumbsMenu :global(.ant-menu-item-group-list .ant-menu-item:hover) {
  @apply bg-accent-1;
}

.BreadcrumbsMenu:not(.ant-menu-horizontal) :global(.ant-menu-item-selected) {
  @apply bg-accent-1;
}
