.textLink {
  transition: all 0.4s ease;
  @apply relative inline-block;
}

.textLink:not(.highlight) {
  @apply text-inherit;
}

.textLink:not(.hideUnderline):after {
  content: '';
  @apply bg-info absolute bottom-0 w-0;
  height: 1px;
  left: 50%;
  transform: translateX(-50%);
  transition: 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55) all;
}
.textLink:hover {
  @apply text-info;
}
.textLink:not(.hideUnderline):hover:after {
  width: 100%;
}
